import React from 'react';
import useSettingsContext from 'contexts/useSettingsContext';
import Holding from './Holding';
import Home from './Home';

import useEffectOnce from 'hooks/useEffectOnce';

export default (props) => {
    let settingsState = useSettingsContext();
    let currentPhase = settingsState.competitionStatus.currentPhasePublicId;
  
    if(currentPhase === "holding"){
        return <Holding />
    } else {
        return <Home />
    }
}