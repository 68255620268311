import React from 'react';
import './Home.scss';
import Link from 'components/Link/Link';
import ModuleBanner from 'assets/images/campaign-2023/banner/banner-headline.png';
import ModuleBanner_2x from 'assets/images/campaign-2023/banner/banner-headline@2x.png';
import ModuleBannerMob from 'assets/images/campaign-2023/banner/banner-headline-mob.png';
import ModuleBannerMob_2x from 'assets/images/campaign-2023/banner/banner-headline-mob@2x.png';

//Keyvis images
import KeyVisual from './KeyVisual';
import Banner from 'components/Banner/Banner';

export default (props) => {

  return (
    <>

    <section className="layout layout-general layout-wrapper layout-wrapper--column ">
        <Banner 
        image={ModuleBanner}
        image2x={ModuleBanner_2x}
        imageMob={ModuleBannerMob}
        imageMob2x={ModuleBannerMob_2x}
        alt={"Theme park ballons"}
        />
    </section>
        <section className="layout-wrapper home__banner">
            <div className="home__entrybar" >
                <div className="home__intro">
                    <div className="type-h2 type-h2--gold">
                        We’re just undergoing some essential maintenance, please check back shortly.
                    </div>
                    <div className="home__intro-body">
                        <p>In the mean time, keep up to date on the latest Cadbury news and tasty treats!</p>
                    </div>
                </div>
                <div className="home__cta">
                    <Link to="https://www.cadbury.co.uk" lozenge>Visit Cadbury Website</Link>
                </div>
            </div>
        </section>
      
     
    </>
  );
}